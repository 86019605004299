import { filterHiddenPages } from '@zyro-inc/site-modules/utils/filterHiddenPages';

export const fetchSiteData = async () => {
	const currentDate = new Date().setHours(0, 0, 0, 0);

	if (import.meta.env.DEV) {
		const playgroundSiteData = await import('@zyro-inc/site-modules/templates/main.json');

		return {
			...playgroundSiteData,
			languages: filterHiddenPages({
				languages: playgroundSiteData.languages,
				currentDate,
				isAstro: false,
				isPrivateModeActive: playgroundSiteData.meta.isPrivateModeActive,
			}),
		};
	}

	const response = await fetch(`${window.location.origin}/data.json`);
	const siteData = await response.json();

	return {
		...siteData,
		languages: filterHiddenPages({
			languages: siteData.languages,
			currentDate,
			isAstro: false,
			isPrivateModeActive: siteData.meta.isPrivateModeActive,
		}),
	};
};
